<template>
  <v-dialog v-model="addLetterDialog" width="800" class="mt-5" persistent>
    <v-card class="modal-card2 grey--text text--darken-2">
      <v-row>
        <v-col>
          <h5 class="mt-3">افزودن نامه مرتبط</h5>
        </v-col>
        <v-col class="text-left">
          <v-btn
            @click="closeDialog()"
            height="40"
            width="95"
            class="blue-borderedBtn"
            >بازگشت</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="custom-input">
        <v-col class="pb-2 d-flex flex-row"
          ><v-select
            label="نوع نامه"
            outlined
            dense
            :items="letterTypes"
            v-model="filter.type"
            hide-details
            @change="
              filter.sender = '';
              filter.receiver = '';
            "
          >
          </v-select>
          <v-btn
            v-if="filter.type"
            @click="filter.type = ''"
            class="ms-2 red-mainBtn"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Trash</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <div v-if="!filter.type" class="pt-3 pb-2 text-right">
        <span class="red--text"
          >برای تعیین نام فرستنده و نام گیرنده نوع نامه را مشخص کنید.</span
        >
      </div>
      <v-row class="custom-input">
        <v-col class="pb-2 d-flex flex-row"
          ><v-autocomplete
            label="نام فرستنده"
            outlined
            dense
            item-text="choiceName"
            item-value="id"
            :items="filter.type == 'inbox' ? contacts : customEmployees"
            v-model="filter.sender"
            :class="!filter.type ? 'disabled-box' : ''"
          ></v-autocomplete>
          <v-btn
            v-if="filter.sender"
            @click="filter.sender = ''"
            class="ms-2 red-mainBtn"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Trash</v-icon></v-btn
          >
        </v-col>
        <v-col class="pb-2 d-flex flex-row"
          ><v-autocomplete
            label="نام گیرنده"
            outlined
            dense
            item-text="choiceName"
            item-value="id"
            :items="filter.type == 'outbox' ? contacts : customEmployees"
            v-model="filter.receiver"
            :class="!filter.type ? 'disabled-box' : ''"
          ></v-autocomplete>
          <v-btn
            v-if="filter.receiver"
            @click="filter.receiver = ''"
            class="ms-2 red-mainBtn"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Trash</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-row class="custom-input">
        <v-col class="pb-2 d-flex flex-row"
          ><v-text-field
            label="جستجو در شماره نامه"
            outlined
            dense
            v-model="filter.letterNo"
          ></v-text-field>
          <v-btn
            v-if="filter.letterNo"
            @click="filter.letterNo = ''"
            class="ms-2 red-mainBtn"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Trash</v-icon></v-btn
          >
        </v-col>

        <v-col class="pb-2 d-flex flex-row"
          ><v-text-field
            label="جستجو در موضوع نامه"
            outlined
            dense
            v-model="filter.title"
          ></v-text-field>
          <v-btn
            v-if="filter.title"
            @click="filter.title = ''"
            class="ms-2 red-mainBtn"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Trash</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <div class="pt-5 pb-2 h4 font-weight-bold text-right">
        تاریخ ارسال نامه:
      </div>
      <v-row class="custom-input">
        <v-col class="pb-2 d-flex flex-row"
          ><v-text-field
            label="از تاریخ"
            outlined
            dense
            id="letterStartDate2"
            v-model="filter.startDate"
          ></v-text-field
          ><v-btn
            class="ms-2 blue-borderedBtn"
            id="letterStartDate2"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Calendar</v-icon></v-btn
          >
          <date-picker
            element="letterStartDate2"
            v-model="filter.startDate"
            color="#4C81C9"
          ></date-picker>
        </v-col>
        <v-col class="pb-2 d-flex flex-row"
          ><v-text-field
            label="تا تاریخ"
            outlined
            dense
            id="letterEndDate"
            v-model="filter.endDate"
          ></v-text-field
          ><v-btn
            class="ms-2 blue-borderedBtn"
            id="letterEndDate"
            width="40"
            min-width="40"
            height="40"
            ><v-icon>$Calendar</v-icon></v-btn
          >
          <date-picker
            element="letterEndDate"
            v-model="filter.endDate"
            color="#4C81C9"
          ></date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="borderBox">
            <div class="box-title">جستجو در تگ</div>
            <div class="custom-input mt-2 d-flex flex-row">
              <v-text-field
                placeholder="عنوان تگ را وارد کنید..."
                outlined
                dense
                v-model="tagName"
                @keyup.enter="tagName ? addTag() : ''"
              >
              </v-text-field>
              <v-btn
                class="ms-3 blue-borderedBtn"
                height="39"
                @click="addTag()"
                :disabled="!tagName"
                >افزودن</v-btn
              >
            </div>
            <div class="d-flex flex-row">
              <div
                v-for="(item, index) in filter.tags"
                :key="index"
                class="me-2 mt-3 blue-badge"
              >
                <span>{{ item }}</span>
                <v-icon
                  small
                  class="pointer ms-1"
                  @click="removeRelatedTag(index)"
                  >mdi-close</v-icon
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="text-left mt-4">
        <v-btn
          height="48"
          width="110"
          class="blue-mainBtn"
          @click="lettersSearch"
        >
          <v-icon class="me-1">$Magnify</v-icon> جستجو</v-btn
        >
      </div>
      <div class="secretariat-table mt-8">
        <b-table
          responsive
          show-empty
          stacked="sm"
          :current-page="currentPage"
          :per-page="perPage"
          :items="relatedLetters"
          :fields="fields"
          empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
          empty-text="رکوردی برای نمایش وجود ندارد"
          striped
          thead-class="headClass"
          tbody-class="bodyClass"
          :busy="isBusy"
        >
          <template v-slot:cell(index)="data">
            <div
              style="text-align: center; vertical-align: middle"
              class="pt-0"
            >
              {{ data.index + 1 + perPage * (currentPage - 1) }}
            </div>
          </template>
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell()="data">
            <div
              style="text-align: center; vertical-align: middle"
              class="pt-0"
            >
              {{ data.value }}
            </div>
          </template>

          <template v-slot:cell(operation)="data">
            <div style="text-align: center; vertical-align: middle">
              <v-btn
                @click="$emit('addLetters', data.item)"
                height="40"
                class="blue-mainBtn pa-3"
                >افزودن</v-btn
              >
            </div>
          </template>
          <div slot="table-busy" class="text-center text-danger my-2">
            <v-progress-circular
              indeterminate
              color="primary"
              class="align-middle"
            ></v-progress-circular>
          </div>
        </b-table>
        <div class="d-flex flex-row justify-content-end blue-custom-pagination">
          <v-btn @click="goToFirst()" class="navigation-btn"
            ><v-icon>$ArrowDoubleRight</v-icon></v-btn
          >
          <v-pagination
            v-if="!isBusy"
            v-model="currentPage"
            :length="Math.ceil(relatedLetters.length / perPage)"
            :total-visible="5"
            prev-icon="$ArrowLeft"
            next-icon="$ArrowRight"
          ></v-pagination>
          <v-btn @click="goToLast()" class="navigation-btn"
            ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";
import Axios from "axios";
export default {
  props: [
    "addLetterDialog",
    "contacts",
    "customEmployees",
    "signatories",
    "selectEmployees",
  ],
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      openDialog: false,
      isBusy: false,
      fields: [
        { key: "index", label: "#" },
        { key: "letterNo", label: "شماره نامه" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
        { key: "operation", label: "عملیات" },
      ],
      relatedLetters: [],
      currentPage: 1,
      perPage: 4,
      letterTypes: [
        { value: "internal", text: "سازمانی" },
        { value: "inbox", text: "ورودی" },
        { value: "outbox", text: "خروجی" },
      ],
      filter: {
        type: "",
        sender: "",
        receiver: "",
        letterNo: "",
        title: "",
        tags: [],
        startDate: moment(new Date())
          .subtract(7, "day")
          .format("jYYYY/jMM/jDD"),
        endDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      },
      tagName: "",
      contactsLoading: false,
      employeesLoading: false,
      signatoriesLoading: false,
    };
  },
  methods: {
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(this.relatedLetters.length / this.perPage);
    },
    addTag() {
      if (!this.filter.tags.includes(this.tagName.trim())) {
        this.filter.tags.push(this.tagName.trim());
        this.tagName = "";
      } else {
        this.$toast("این تگ پیش از این افزوده شده است.", "error");
      }
    },
    removeRelatedTag(index) {
      this.filter.tags.splice(index, 1);
    },
    lettersSearch() {
      this.isBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/search",
        {
          senderId: this.filter.sender,
          receiverId: this.filter.receiver,
          letterNo: this.filter.letterNo,
          title: this.filter.title,
          type: this.filter.type,
          tags: this.filter.tags.length ? this.filter.tags : "",
          startDate: this.filter.startDate,
          endDate: this.filter.endDate,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.relatedLetters = res.data;
            this.currentPage = 1;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    closeDialog() {
      this.openDialog = false;
      this.$emit("closeAddLetterDialog", this.openDialog);
    },
  },
  mounted() {
    this.openDialog = this.addLetterDialog;
    this.lettersSearch();
  },
};
</script>
